import React, { ReactNode, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import infoIcon from '../assets/images/info-icon.svg';
import warningIcon from '../assets/images/warning-icon.svg';
import successIcon from '../assets/images/success-icon.svg';
import dangerIcon from '../assets/images/danger-icon.svg';

const allStates = {
  info: infoIcon,
  warning: warningIcon,
  success: successIcon,
  danger: dangerIcon
};

interface Props {
  type: (keyof typeof allStates);
  text?: string | ReactNode;
  showIcon?: boolean;
  dismissible?: boolean;
  onClose?: () => void;
}

const AlertComponent = ({ type = 'info', text, showIcon, dismissible = true, onClose }: Props) => {
  const [currentIcon, setCurrentIcon] = useState<string>(allStates[type]);
  const [show, setShow] = useState(true);

  useEffect(() => {
    setCurrentIcon(allStates[type]);
  }, [type]);

  const handleClose = () => {
    if (onClose) onClose();
    setShow(false);
  };

  return (
    <Alert variant={type} show={show} onClose={handleClose} dismissible={dismissible}>
      {showIcon && (
        <i className="alert__icon">
          <img src={currentIcon} alt={`${type}-icon`} />
        </i>
      )}
      <span className="alert__text">{text}</span>
    </Alert>
  );
};

export default AlertComponent;
