import React, { useContext, useEffect, useState } from 'react';
import BankDetailData from './BankDetailData';
import { Card, Col, Row } from 'react-bootstrap';
import SadSmileyIcon from '../../../assets/images/dashboard/sad-smiley.svg';
import CButton from '../../../shared/forms/Button';
import { AuthContext } from '../../../contexts/useAuthContext';
import { PlanContext } from '../plans/usePlanContext';
import ConfirmationModal from '../../../shared/forms/ConfirmationModal';
import { LogolessBanks } from '../../../utils/constants';
import { BusinessContext } from '../business-profile/useBusinessContext';
import {BankDetailAttributeType, BankDetailType} from '../../../utils/types/subscription_types';

const BankDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const [bankDetails, setBankDetails] = useState([]);
  const [refetchBankDetails, setRefetchBankDetails] = useState(false);
  const [id, setId] = useState<number>();
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [businessDetails, setBusinessDetails] = useState(false);

  const { state: authState } = useContext(AuthContext);
  const { editBankDetail, bankDetailList, deleteBankDetail } = useContext(PlanContext);
  const { getBusiness } = useContext(BusinessContext);

  useEffect(() => {
    (async () => {
      const result = await bankDetailList();
      if (!result || result.length === 0) return;

      const attributes = result.map(({ attributes }: BankDetailType) => attributes);
      setBankDetails(attributes);
    })();
  }, [refetchBankDetails, authState.currentBusiness]);

  useEffect(() => {
    (async () => {
      const business = await getBusiness();
      if (!business) return;

      const businessAttributes = business.attributes;
      setBusinessDetails(businessAttributes.bank_detail_added);
    })();
  },[bankDetailList]);

  const maxCount = 3;

  const prepareBankDetailEditData = (bankDetailId: number) => {
    setShowEditModal(true);
    setId(bankDetailId);
  };

  const prepareBankDetailDeleteData = (bankDetailId: number) => {
    setShowDeleteModal(true);
    setId(bankDetailId);
  };

  const setAsDefault = async () => {
    if (id === undefined) return;

    setLoadingEdit(true);
    await editBankDetail(id);
    setLoadingEdit(false);
    setShowEditModal(false);
    setRefetchBankDetails(!refetchBankDetails);
  };

  const handleDeleteBankDetail = async () => {
    if (id === undefined) return;

    setLoadingDelete(true);
    try {
      await deleteBankDetail(id);
      setLoadingDelete(false);
      setShowDeleteModal(false);
      setRefetchBankDetails(!refetchBankDetails);
    } catch (error) {
      setLoadingDelete(false);
      setShowDeleteModal(false);
    }
  };

  const getBankIcon = (bankName: string) => {
    if (LogolessBanks.includes(bankName)) {
      return 'https://corna-production.s3.eu-central-1.amazonaws.com/logo.svg';
    }
    const name = `${bankName.replace(/[\])}[{(]/g, '').replace(/ +/g, '-').toLowerCase()}-icon`;
    return require(`../../../assets/images/payment/banks/${name}.svg`);
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <strong className="text-dark">{`Bank details (${bankDetails.length}/${maxCount})`}</strong>
        {businessDetails && bankDetails.length < 3 &&
          <CButton small onClick={() => setShowModal(true)}>Add new bank</CButton>}
      </div>
      <p>You will receive your payout in your default bank detail</p>

      <br />
      <div>
        <BankDetailData
          show={showModal}
          refetchDetails={refetchBankDetails}
          setRefetchDetails={setRefetchBankDetails}
          onClose={() => setShowModal(false)}
        />
        <ConfirmationModal
          show={showEditModal}
          title="Set as default"
          cta="Update"
          type="bank detail"
          onClose={() => setShowEditModal(false)}
          onClick={setAsDefault}
        />
        <ConfirmationModal
          show={showDeleteModal}
          cta="Delete"
          type="bank detail"
          onClose={() => setShowDeleteModal(false)}
          onClick={handleDeleteBankDetail}
        />
        <Row>
          {bankDetails.length === 0 ?
            <Col md={4}>
              <Card className="home-card payment-card">
                <Card.Body>
                  <div className="text-center mt-4">
                    <img src={SadSmileyIcon} alt="Empty Icon" />
                    <p className="home-text__desc mt-2">You have not added any bank details</p>

                    <div className="mt-2">
                      <CButton small onClick={() => setShowModal(true)}>
                        Add a new bank
                      </CButton>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col> : (
              bankDetails.map((bankDetail: BankDetailAttributeType) => {
                return (
                  <Col md={4} className="mb-4 mb-md-0" key={bankDetail.id}>
                    <Card className="payment-bank">
                      <Card.Body className="payment-card__details">
                        <React.Fragment>
                          <img src={getBankIcon(bankDetail.bank_name)} alt={bankDetail.bank_name} />
                          {bankDetail.default &&
                            <CButton
                              small
                              styles={{float: 'right'}}
                              smallText
                              color="inverted"
                            >
                              Primary
                            </CButton>
                          }
                        </React.Fragment>
                        <p className="small mt-2">Account number</p>
                        <small className="text-dark">{bankDetail.account_number}</small>

                        <p className="small mt-3">Account name</p>
                        <small className="text-dark">{bankDetail.account_name}</small>
                      </Card.Body>
                      <div className="payment-card-button-set">
                        <div className="d-flex justify-content-end">
                          {!bankDetail.default &&
                            <CButton
                              smallNoLoader
                              smallText
                              loading={loadingEdit}
                              onClick={() => prepareBankDetailEditData(bankDetail.id)}
                            >
                              Set as primary
                            </CButton>
                          }
                          <CButton
                            smallNoLoader
                            smallText
                            color="transparent"
                            loading={loadingDelete}
                            onClick={() => prepareBankDetailDeleteData(bankDetail.id)}
                          >
                            Remove
                          </CButton>
                        </div>          
                      </div>                    
                    </Card>
                  </Col>
                );
              })
            )
          }
        </Row>
      </div>
    </div>
  );
};

export default BankDetails;
