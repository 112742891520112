import React from 'react';
import {Redirect, RouteComponentProps, withRouter} from 'react-router-dom';
import SettingsPlans from '../dashboard/settings/plans';
import { Constants } from '../utils/constants';
import AlertComponent from '../shared/AlertComponent';
import {logo} from '../utils/helpers';

interface IProps extends RouteComponentProps {
  showAlert: boolean;
}

const SelectPlan = ({ showAlert = false }: IProps): JSX.Element => {
  const presetData = JSON.parse(localStorage.getItem(Constants.StorageKey.PresetData) as string);

  return (
    <div className="c-auth__gradient">
      {presetData ?
        <React.Fragment>
          <a href={Constants.Links.Auth.Path.Login}>
            <img src={logo} alt="Logo" className="app-logo" />
          </a>

          <br />
          {showAlert && <AlertComponent
            text="Select a plan before you log in"
            type="warning"
            showIcon={true}
          />}
          <h3 className="mb-3">Select a plan for your 1 month free subscription</h3>
          <div className="c-auth__content text-left" style={{width: '90%'}}>
            <SettingsPlans onboardingUser={true} />
          </div>
        </React.Fragment> :
        <Redirect to={Constants.Links.Auth.Path.Login}/>
      }
    </div>
  );
};

export default withRouter(SelectPlan);
