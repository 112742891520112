import React, { useContext, useState, useEffect } from 'react';
import { CTable, CTableRow, CTableData } from '../../../shared/CTable';
import EmptyState from '../../../shared/EmptyState';
import { Constants } from '../../../utils/constants';
import {AuthContext} from '../../../contexts/useAuthContext';
import { AnalyticsContext } from '../useAnalyticsContext';
import { BusinessContext } from '../../settings/business-profile/useBusinessContext';
import {AnalyticsCustomerAttributeType, AnalyticsCustomerStateObjectType,} from '../../../utils/types/analytics_types';
import CButton from '../../../shared/forms/Button';
import CConfirmationModal from '../../../shared/forms/ConfirmationModal';
import {successToast} from '../../../utils/helpers';
import starIcon from '../../../assets/images/star-icon.svg';
import CustomerRatings from './CustomerRatings';
import infoIcon from '../../../assets/images/info-icon.svg';
import {formatRatingScore} from '../../../utils/stringManipulation';

const CustomerTable = () => {
  const { state: authState, isAdmin } = useContext(AuthContext);
  const { state, customerList, blockCustomer, unblockCustomer, customerRatingList } = useContext(AnalyticsContext);
  const { getBusiness } = useContext(BusinessContext);

  const [loading, setLoading] = useState(false);
  const [customersConfig, setCustomersConfig] = useState<AnalyticsCustomerStateObjectType>(state.customers);
  const [refresh, setRefresh] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [businessBlockedCustomerIds, setBusinessBlockedCustomerIds] = useState<number[]>([]);
  const [showCustomerRatings, setShowCustomerRatings] = useState(false);
  const [attributeValues, setAttributeValues] =
    useState<AnalyticsCustomerAttributeType>({} as AnalyticsCustomerAttributeType);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchData();
      setLoading(false);

      const business = await getBusiness();
      if (!business) return;

      const businessAttributes = business.attributes;
      setBusinessBlockedCustomerIds(businessAttributes.blocked_customer_ids);
    })();
  }, [authState.currentBusiness,refresh]);

  useEffect(() => {
    const { customers = {} as AnalyticsCustomerStateObjectType } = state;
    const { items = [], totalCount = 0, pageNumber } = customers;
    setCustomersConfig({ items, totalCount, pageNumber } as AnalyticsCustomerStateObjectType);
  }, [state.customers]);

  const fetchData = async (pageNumber = 1, query = '') => {
    await customerList(pageNumber, query);
  };

  const tableConfig = {
    title: Constants.Table.Title.Customers,
    theadData: isAdmin() ? Constants.Table.Header.Customers : Constants.Table.Header.CustomerStaff,
    totalCount: customersConfig.totalCount,
    pageRange: Constants.PerPageCount.Customers,
    OnSearch: fetchData,
    onPageChange: async (page: number, query: string) => {
      if (customersConfig.totalCount > 0) await fetchData(page, query);
    }
  };

  const isBlocked = (customerId: number) => {
    return businessBlockedCustomerIds.includes(customerId);
  };

  const blockText = (attributes: AnalyticsCustomerAttributeType) => {
    const blockedText = `This action will unblock ${attributes.name}, and they will be able to see your business.`;
    const unblockedText = `This action will block ${attributes.name}, and they will not be able to see your business.`;

    return isBlocked(attributes.id) ? blockedText : unblockedText;
  };

  const successfulActionText = (attributes: AnalyticsCustomerAttributeType) => {
    const blockedText = `You have successfully unblocked ${attributes.name}`;
    const unblockedText = `You have successfully blocked ${attributes.name}`;

    return isBlocked(attributes.id) ? blockedText : unblockedText;
  };

  const prepareBlockData = (attributes: AnalyticsCustomerAttributeType) => {
    setAttributeValues(attributes);
    setShowBlockModal(true);
  };

  const prepareRatingData = async (customerId: number) => {
    await customerRatingList(customerId);
    setShowCustomerRatings(true);
  };

  const handleBlockAction = async () => {
    try {
      setLoading(true);
      isBlocked(attributeValues.id)
        ? await unblockCustomer(attributeValues.id)
        : await blockCustomer(attributeValues.id);
      setLoading(false);
      setShowBlockModal(false);

      const text = successfulActionText(attributeValues);
      successToast(text);
      setRefresh(!refresh);
    } catch (error) {
      setLoading(false);
      setShowBlockModal(false);
    }
  };

  return (
    <div>
      {!state.customers.hasCustomers && !loading ? (
        <EmptyState
          type={Constants.Table.Empty.CustomersTable}
        />
      ) : (
        <CTable {...tableConfig}>
          {customersConfig.items.map(({ attributes }, index) => (
            <React.Fragment key={index}>
              <CConfirmationModal
                show={showBlockModal}
                type="customer"
                customNote={blockText(attributeValues)}
                loading={loading}
                cta={isBlocked(attributeValues.id) ? 'Unblock' : 'Block'}
                onClick={handleBlockAction}
                onClose={() => setShowBlockModal(false)}
              />
              <CustomerRatings
                show={showCustomerRatings}
                onClose={() => setShowCustomerRatings(false)}
                attributes={attributes}
              />
              <CTableRow>
                <CTableData>{attributes.name}</CTableData>
                {isAdmin() && <CTableData>{attributes.email}</CTableData>}
                {isAdmin() && <CTableData>{attributes.profile.attributes.contact_number}</CTableData>}
                {isAdmin() && <CTableData>{attributes.business_booking_count}</CTableData>}
                <CTableData>
                  <div className="d-flex align-items-center">
                    <img src={starIcon}  alt="customer's overall rating" className="analytics-customer-rating" />
                    <span className="pl-1">{formatRatingScore(attributes.rating.score)}</span>
                    <i className="alert__icon pointer" onClick={() => prepareRatingData(attributes.id)}>
                      <sup className="pl-1"><img src={infoIcon} alt="info-icon" width="11" /></sup>
                    </i>
                  </div>
                </CTableData>
                {isAdmin() && <CTableData classes="actions">
                  <CButton
                    small
                    smallText
                    onClick={() => prepareBlockData(attributes)}
                  >
                    {isBlocked(attributes.id) ? 'Unblock' : 'Block'}
                  </CButton>
                </CTableData>}
              </CTableRow>
            </React.Fragment>
          ))}
        </CTable>
      )}
    </div>
  );
};

export default CustomerTable;
