import React, { useContext, useState } from 'react';
import {useHistory} from 'react-router-dom';
import ServiceTable from './ServiceTable';
import CategoryTable from './CategoryTable';
import BaseHeader from '../../shared/Header';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import CategoryData from './CategoryData';
import { AuthContext } from '../../contexts/useAuthContext';
import { Constants } from '../../utils/constants';
import { ServiceContext } from './useServiceContext';
import OnboardingTaskList from '../../shared/OnboardingTaskList';

interface Props {
  onAddCategory: () => void
}

const ServiceHeader = ({ onAddCategory }: Props) => {
  const history = useHistory();

  const { isAdmin } = useContext(AuthContext);
  const { state } = useContext(ServiceContext);

  const handleSelect = (item: string) => {
    if (item === Constants.Services.AddNewOptions[0]) {
      history.push(Constants.Links.Service.Path.CreateService);
      return;
    }
    onAddCategory();
  };

  return (
    isAdmin() && state.categories.hasCategories && (
      <div className="d__box">
        <DropdownButton alignRight title="+ Add New" className="dropdown-icon ">
          {Constants.Services.AddNewOptions.map((item, index) => (
            <Dropdown.Item
              key={index}
              as="button"
              onClick={() => handleSelect(item)}
            >
              {item}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
    )
  ) as JSX.Element;
};

const Services = () => {
  const [showCategory, setShowCategory] = useState<boolean>(false);

  const { state } = useContext(ServiceContext);

  return (
    <React.Fragment>
      <OnboardingTaskList />

      <BaseHeader title="Services">
        <ServiceHeader onAddCategory={() => setShowCategory(true)} />
      </BaseHeader>
      {state.categories.hasCategories && <React.Fragment>
        <ServiceTable />
        <br />
      </React.Fragment>}
      <CategoryTable onAddCategory={() => setShowCategory(true)} />
      <CategoryData
        show={showCategory}
        onClose={() => setShowCategory(false)}
      />
    </React.Fragment>
  );
};

export default Services;
