import React, { useContext, useEffect, useState } from 'react';
import EmptyState from '../../../shared/EmptyState';
import { CTable, CTableData, CTableRow } from '../../../shared/CTable';
import { Constants } from '../../../utils/constants';
import { PlanContext } from '../plans/usePlanContext';
import {PlanStateObjectType} from '../../../utils/types/subscription_types';
import {AuthStateObjectType} from '../../../utils/types/auth_types';
import {cardLast4} from '../../../utils/stringManipulation';

const PaymentTable = ({ authState }: { authState: AuthStateObjectType }) => {
  const { state, paymentList } = useContext(PlanContext);

  const [loading, setLoading] = useState(false);
  const [paymentConfig, setPaymentConfig] = useState<PlanStateObjectType>(state);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchData();
      setLoading(false);

      const { items = [], totalCount = 0, pageNumber } = state;
      setPaymentConfig({ items, totalCount, pageNumber } as PlanStateObjectType);
    })();
  }, [authState.currentBusiness]);

  const fetchData = async (pageNumber = 1) => {
    await paymentList(pageNumber);
  };

  const tableConfig = {
    title: Constants.Table.Title.Payments,
    theadData: Constants.Table.Header.Payments,
    totalCount: paymentConfig.totalCount,
    pageRange: Constants.PerPageCount.Payments,
    showSearchOption: false,
    onPageChange: async (page: number) => {
      if (paymentConfig.totalCount > 0) await fetchData(page);
    }
  };

  return (
    <div>
      {!state.hasPayments && !loading ? (
        <EmptyState type={Constants.Table.Empty.PaymentTable} />
      ) : (
        <React.Fragment>
          <CTable {...tableConfig}>
            {state.items.map(({ attributes }, index) => (
              <CTableRow key={index}>
                <CTableData>
                  <strong>{attributes.plan_name}</strong>
                </CTableData>
                <CTableData>{attributes.amount}</CTableData>
                <CTableData>
                  {new Date(attributes.start_date).toDateString().split(' ').slice(1).join(' ')}
                </CTableData>
                <CTableData>
                  {new Date(attributes.next_payment_date).toDateString().split(' ').slice(1).join(' ')}
                </CTableData>
                <CTableData>{cardLast4(attributes.card_last4)}</CTableData>
                <CTableData />
              </CTableRow>
            ))}
          </CTable>
        </React.Fragment>
      )}
    </div>
  );
};

export default PaymentTable;
